'use client';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import {
  Input,
  SearchMD,
  Star01,
  ToggleGroup,
  ToggleGroupItem,
  Typography,
  typographyVariants,
} from '@v2/ui';
import { Slider, SliderRange, SliderThumb, SliderTrack } from '@v2/ui/Slider';
import { cn } from '@v2/utils';
import { useEffect, useMemo, useState } from 'react';
import { useGetHotels } from '../../hooks/use-hotels-list';
import { HOTEL_FEATURES_LIST, STAR_RATINGS_LIST } from '../data';

function FieldWrapper(props: {
  label: string;
  className?: string;
  children: React.ReactNode;
}) {
  const { label, className, children } = props;
  return (
    <div className={cn('flex flex-col gap-y-3', className)}>
      <Typography size="tmd" className="font-bold">
        {label}
      </Typography>

      {children}
    </div>
  );
}
function RatingField(props: {
  value: string[];
  onValueChange: (val: string[]) => void;
  className?: string;
}) {
  const { value, onValueChange } = props;
  return (
    <FieldWrapper label="Star rating">
      <ToggleGroup
        type="multiple"
        variant="primary"
        className="justify-start gap-2"
        size="sm"
        value={value}
        onValueChange={onValueChange}
      >
        {STAR_RATINGS_LIST.map((rating) => (
          <ToggleGroupItem
            // @ts-ignore
            value={rating}
            key={rating}
            className={'w-[64px] gap-1 rounded-lg p-0'}
          >
            <span
              className={cn(
                typographyVariants({
                  size: 'tmd',
                }),
                'font-medium text-current'
              )}
            >
              {rating}
            </span>{' '}
            <Star01
              size="16"
              fill="#667085"
              pathProps={{ stroke: '#667085' }}
            />
          </ToggleGroupItem>
        ))}
      </ToggleGroup>
    </FieldWrapper>
  );
}

function HotelFeatureField(props: {
  value: string[];
  onValueChange: (val: string[]) => void;
}) {
  const { value, onValueChange } = props;
  return (
    <FieldWrapper label="Hotel feature">
      <ToggleGroup
        type="multiple"
        variant="primary"
        className="flex-wrap justify-start gap-2"
        size="sm"
        value={value}
        onValueChange={onValueChange}
      >
        {HOTEL_FEATURES_LIST.map(({ icon: Icon, label, value }, index) => (
          <ToggleGroupItem
            value={value}
            key={value}
            className={'gap-1 rounded-lg px-[12px] text-current'}
          >
            {Icon}
            <span
              className={cn(
                typographyVariants({
                  size: 'tmd',
                }),
                'font-medium text-current'
              )}
            >
              {label}
            </span>{' '}
          </ToggleGroupItem>
        ))}
      </ToggleGroup>
    </FieldWrapper>
  );
}

function PriceTravelerRangeField(props: {
  value: number[];
  onValueChange: (val: number[]) => void;
}) {
  const { value, onValueChange } = props;

  const { maxHotelPrice } = useGetHotels();

  return (
    <FieldWrapper label="Filter by">
      <Typography
        size="tmd"
        className="mb-1.5 flex items-center justify-between font-medium"
      >
        <span>$0</span>
        <span>${maxHotelPrice.toFixed(0)}</span>
      </Typography>

      <Slider
        min={255}
        max={maxHotelPrice}
        minStepsBetweenThumbs={2}
        value={value}
        onValueChange={onValueChange}
      >
        <SliderTrack>
          <SliderRange />
        </SliderTrack>
        <SliderThumb />
        <SliderThumb />
      </Slider>
    </FieldWrapper>
  );
}

function DistanceToVenueField(props: {
  value: number[];
  onValueChange: (val: number[]) => void;
  className?: string;
}) {
  const { value, onValueChange, className } = props;
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  return (
    <FieldWrapper label="Distance to Venue" className={className}>
      <Typography
        size="tmd"
        className="mb-1.5 flex items-center justify-between font-medium"
      >
        <span>0 mi</span>
        <span>50 mi</span>
      </Typography>
      <Slider
        min={0}
        max={50}
        defaultValue={[15]}
        value={currentValue}
        onValueChange={(val) => {
          onValueChange(val);
          setCurrentValue(val);
        }}
      >
        <SliderTrack>
          <SliderRange />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </FieldWrapper>
  );
}

function QueryField(props: {
  value?: string;
  onValueChange: (val: string) => void;
  label?: string;
}) {
  const { value, onValueChange, label = 'Search by property name' } = props;

  const [currentVal, setCurentValue] = useState(value);

  useEffect(() => {
    if (currentVal === value) return;

    setCurentValue(value);
  }, [value]);
  return (
    <FieldWrapper label={label}>
      <Input
        value={currentVal}
        extent={'lg'}
        placeholder="E.g. Mariott"
        className="focus:border-gray-800"
        startIcon={<SearchMD pathProps={{ stroke: '#667085' }} />}
        onChange={(e) => {
          const value = e.target.value;
          onValueChange(value);
          setCurentValue(value);
        }}
      />
    </FieldWrapper>
  );
}
export {
  DistanceToVenueField,
  HotelFeatureField,
  PriceTravelerRangeField,
  QueryField,
  RatingField,
};
