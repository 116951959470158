/* eslint-disable @typescript-eslint/naming-convention */
import { Settings } from 'react-slick';

// seat map div #id
export const SEAT_MAP_ID = 'seat-map';

// seat map section color
export const MAP_SECTION_COLOR = 'rgba(50,108,100)';

// next-iron-session cookie name
export const SESSION_COOKIE_NAME = '_crw';
// keys for session cookies
export enum SESSION_KEYS {
  USER = 'USER',
  TOKEN = 'TOKEN',
  FOLLOW = 'FOLLOW',
}

export enum NEXT_STEPS {
  hotel = 'hotel',
  flight = 'flight',
  tickets = 'tickets',
  checkout = 'checkout',
}

export enum AGE {
  CHILD = 12,
  INFANT = 2,
  ADULT = 13,
}

// analytics event types
export enum TrackedEvents {
  HOTEL_SELECTED = 'HOTEL_SELECTED',
  TICKET_SELECTED = 'TICKET_SELECTED',
  CREATE_ORDER = 'CREATE_ORDER',
  BUNDLE_TYPE = 'BUNDLE_TYPE',
  BYP_TICKET_SELECTED = 'BYP_TICKET_SELECTED',
  BYP_ROOM_SELECTED = 'BYP_ROOM_SELECTED',
  SIGN_IN_TYPE = 'SIGN_IN_TYPE',
  QUOTATION_SENT = 'QUOTATION_SENT',
  OFFER_SHARED_WITH_FRIENDS = 'OFFER_SHARED_WITH_FRIENDS',
  VIEW_CHECKOUT_PAGE = 'VIEW_CHECKOUT_PAGE',
}

// list of SorterOption for select
export const listSorterOption = [
  { name: 'Distance', value: 'distance-low-high' },
  { name: 'Price (low to high)', value: 'price-low-high' },
  { name: 'Price (high to low)', value: 'price-high-low' },
  { name: 'Stars (low to high)', value: 'rating-low-high' },
  { name: 'Stars (high to low)', value: 'rating-high-low' },
];

// responsive
export const responsive = {
  mobile: 900,
};

// list of item for header
export const headerItems = [];

const breakPointSeetings = {
  slidesToShow: 1,
  centerMode: true,
  centerPadding: '0',
  useCSS: true,
};

export const imageSliderSettings: Settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  dotsClass: 'slick-dots slick-dots--image',
  responsive: [
    {
      breakpoint: 400,
      settings: breakPointSeetings,
    },
    {
      breakpoint: 800,
      settings: breakPointSeetings,
    },
    {
      breakpoint: 1200,
      settings: breakPointSeetings,
    },
    {
      breakpoint: 1600,
      settings: breakPointSeetings,
    },
    {
      breakpoint: 9999,
      settings: breakPointSeetings,
    },
  ],
};
